.downloadCard-component--wrapper {
    display: flex;
    justify-content: space-between;
    background: var(--service-card-bg);
    backdrop-filter: blur(2px);
    border-radius: 16px;
    margin: 10px 0;
    max-width: 832px;
    padding: 32px;
    flex-wrap: wrap;
    gap: 10px;
    &:hover {
        background: var(--service-card-bg-accent);
    }
    & > div {
        display: flex;
        align-items: center;
        gap: 10px;
        .left-column {
            & > div {
                display: flex;
                align-items: center;
            }
        }
        .right-column {
            display: flex;
            flex-flow: column;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                color: var(--text-primary);
                text-align: left;
                &:first-child {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 16px;
                }
                &:nth-child(2) {
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFEF11;
                }
                &:nth-child(3) {
                    font-weight: 400;
                    font-size: 14px;
                    color: #B3B8C7;
                }
            }
        }
    }
    .actions--wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: start;
        .downloadCard-download--btn {
            background: rgba(14, 199, 217, 0.1);
            border-radius: 8px;
            height: 32px;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #0EC7D9;
            }
            &:disabled {
                opacity: 0.4;
                border: 1px solid #0EC7D9;
                background-color: transparent;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .downloadCard-component--wrapper {
        flex-wrap: wrap;
        gap: 10px;
        .actions--wrapper {
            width: 100%;
            button:first-child {
                flex: 1;
            }
        }
    }
}
