.service-card {
    width: 100%;
    max-width: 832px !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    border: 0;
    padding: 15px;
    background: var(--service-card-bg);
    backdrop-filter: blur(4px);
    border-radius: 16px;
    &:hover {
        background: var(--service-card-bg-accent);
    }
    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background: rgba(26, 206, 179, 0.1);
        border-radius: 8px;
    }
    .service-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & div:nth-child(1) {
            display: flex;
            gap: 15px;
        }
        & div:nth-child(2) {
            display: flex;
            gap: 2px;
            button {
                background: transparent;
                border: 0;
                padding: 0 5px;
                width: 32px;
                &:hover {
                    svg path {
                        fill: var(--service-card-action-bg);
                    }
                }
            }
        }
        h5 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: var(--text-primary);
            text-transform: capitalize;
            margin-bottom: 0;
        }
    }
    .service-data {
        display: flex;
        //justify-content: space-between;
        gap: 5%;
    }
    .service-card--item {
        display: flex;
        width: 150px;
        max-width: 150px;
        min-width: 150px;
        margin: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .service-card--item.service-date span:last-child{
        display: flex;
        width: 120px;
    }
    .service-card--item.service-company {
        min-width: 200px;
    }
    .service-company, .service-status, .service-date, .service-subscribe, .service-actions {
        span:first-child {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #B3B8C7;
        }
    }
    .service-status, .service-subscribe, .service-actions {
        align-items: center;
    }

    .service-subscribe {
        button.ant-switch {
            background-color: #D8DBE3;
        }
        button.ant-switch.ant-switch-checked {
            background-color: #1ACEB3;
        }
    }

    .actions-btn {
        border: none;
        border-radius: 0;
        width: 46px;
        height: 46px;
        background-color: red;
        svg {
            fill: red;
        }
    }
    .history-btn, .download-btn {
        &:hover {
            background-color: #00abcc;
            svg {
                fill: #fefefe;
            }
        }
    }
    .remove-btn {
        &:hover {
            background-color: #c62828;
            svg {
                fill: #fefefe;
            }
        }
    }

    .service-card--item.restore-btn--arrow {
        width: 150px !important;
        flex: none;
    }
}
.remove-btn-dummy {
    cursor: not-allowed !important;
}

// media
@media screen and (max-width: 480px) {
    .service-card .service-data {
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;
        .service-card--item {
            width: 150px;
            min-width: 150px;
            gap: 10px;
            .service-company, .service-date {
                span:first-child {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                }
            }
        }
    }
    .service-data {
        //flex-direction: column;
        .service-status, .service-subscribe, .service-actions {
            align-items: flex-start;
        }
        .service-card--item.service-date span:last-child{
            //width: auto;
            //margin-left: 1.8rem;
        }
        .status-component, .service-subscribe button, .actions-wrapper {
            //margin-left: 1.8rem;
        }
    }
}

@media screen and (min-width: 480px) {
    .service-card  .service-data {
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 480px) {
.ant-modal.mobile-modal {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0;
    .ant-modal-content {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: #1C202C;
        border-radius: 24px 24px 0 0;
        ul {
            padding-left: 0;
            > .line {
                display: flex;
                justify-content: center;
                height: 10px;
                margin-top: -15px;
                img {
                    width: 50px;
                }
            }
            li {
                display: flex !important;
                border-bottom: 1px solid #32384A;
                text-decoration: none;
                gap: 10px;
                padding: 20px 0;
                p{
                    margin-bottom: 0;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }
}
}

@media screen and (min-width: 768px) {
    .service-card .service-data {
        //gap: 4rem;
        .service-card--item {
            width: 120px;
            min-width: 120px;
        }
        .service-card--item.service-company {
            min-width: 180px;
        }
    }
}
