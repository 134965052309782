.custom-notification--container {
    background-color: #032341 !important;
    border-radius: 8px !important;
    .ant-notification-notice-message {
        font-family: 'Gilroy Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
        color: #FFFFFF;
    }
    .ant-notification-notice-description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #9FAED7;
    }
}

@media screen and (max-width: 480px) {
    .ant-notification-notice.custom-notification--container {
        width: 300px;
        padding: 10px 15px;
        .ant-notification-notice-message {
            font-size: 12px;
            margin-bottom: 0;
        }
        .ant-notification-notice-description {
            line-height: 15px;
            font-size: 10px;
        }
        .ant-notification-notice-close {
            top: 5px;
            right: 8px;
            img {
                width: 18px;
            }
        }
    }
}