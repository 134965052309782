.custom-pagination {
    margin: 20px 0 !important;
    strong {
        padding-top: 5px;
        font-size: 14px;
        color: #00abcc;
    }
    button {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        color: #FFFFFF;
        &:disabled {
            color: rgba(255, 255, 255, .4);
        }
    }
}
