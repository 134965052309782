.restore-file--container {
    width: 100%;
    max-width: 980px;
    margin-bottom: 20px;
    color: #f6f7f9;
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B3B8C7;
    }
    .alert-container {
        display: flex;
        justify-content: space-between;
        background: #73BF16;
        color: #f6f7f9;
        padding: 15px 10px;
        align-items: center;
        border: none;
        border-radius: 6px;
        img {
            width: 8px;
        }
    }
    .description {
        margin: 15px 0;
    }
    .restore-file--title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        h5 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: var(--text-primary);
            text-transform: capitalize;
        }
    }
    .restore-file-page--btn {
        color: #0EC7D9;
        padding: 4px 0;
    }
    .restore-file--filter {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        .ant-input-prefix {
            padding-right: 10px;
        }
        .ant-select-selector {
            height: 48px !important;
        }
        input {
            background-color: transparent;
            -webkit-text-fill-color: var(--modal-input-text);
            &::placeholder {
                color: #7D8499 !important;
                -webkit-text-fill-color: #7D8499;
            }
            &:hover {
                color: var(--modal-input-text) !important;
            }
            &:focus {
                color: var(--modal-input-text) !important;
            }
        }
        & > div:nth-child(2) {
            position: relative;
            max-width: 300px;
        }
        .search-input, .datepicker-input {
            height: 48px;
            border: 1px solid #D8DBE3;
            border-radius: 8px;
            background-color: transparent;
        }
        .search-input {
            width: 400px;
        }
        .datepicker-input {
            width: 300px;
        }
        .custom-rangepicker-dropdown {

        }
        .hidden-select {
            position: absolute;
            height: 48px;
            width: 300px;
            z-index: 9999;
            opacity: 0;
            left: 0;
        }
        .restore-file-search--wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            label {
                color: var(--text-primary);
            }
        }
    }
    .selection-actions--wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0;
        & > div {
            display: flex;
            gap: 10px;
        }
        .selection-counter--text {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #B3B8C7;
            display: flex;
            align-items: center;
        }
        .ant-btn.ant-btn-link.selection-restoreFile--btn {
            background: rgba(14, 199, 217, 0.1);
            border-radius: 8px;
            height: 32px;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #0EC7D9;
            }
            &:disabled {
                opacity: 0.2;
                border: 1px solid #0EC7D9;
                background-color: transparent;
            }
        }
    }
    .ant-table {
        background: transparent !important;
    }
    .ant-table-content {
        //background-color: #2d2d2d;
        overflow-x: scroll;
        table {
            border-spacing: 0 2px;
        }
        .ant-table-cell {
            border-bottom: 0;
            padding: 10px;
            img {
                cursor: pointer;
            }
        }
        .ant-table-row.ant-table-row-level-0.ant-table-row-selected {
            td {
                background-color: var(--table-row-selected-bg);
            }
        }
        .ant-table-row.ant-table-row-level-0:hover {
            td {
                background-color: var(--table-row-selected-bg);
            }
        }
        .ant-table-row-expand-icon-cell {
            text-align: left;
            padding: 0;
        }
        .ant-table-cell-row-hover {
            background-color: rgba(14, 199, 217, 0.1);
        }
        .restore-file-actions-wrapper img {
            width: 24px;
        }
        .ant-table-tbody {
            tr {
                background: var(--table-row-bg);
                backdrop-filter: blur(4px);
                border-radius: 4px;
            }
            .ant-table-cell span.file-name-wrapper {
                color: var(--modal-h2) !important;
                text-transform: capitalize;
            }
        }
    }
    .ant-table-content .ant-table-thead tr .ant-table-cell {
        background-color: transparent !important;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B3B8C7;
    }
    .ant-empty-description {
        color: #fefefe;
    }
}

.custom-rangepicker-dropdown.dark {
    background-color: #141318;
    .ant-picker-panel-container {
        background-color: #141318;
        .ant-picker-header {
            color: #f6f7f9;
            button span {
                color: #f6f7f9;
            }
        }
        .ant-picker-content {
            th, tr, td {
                color: #f6f7f9;
            }
        }
    }
}

.custom-rangepicker-dropdown.light {
    background-color: #FFFFFF;
    .ant-picker-panel-container {
        background-color: #FFFFFF;
        .ant-picker-header {
            color: #1C202C;
            button span {
                color: #1C202C;
            }
        }
        .ant-picker-content {
            th, tr, td {
                color: #1C202C;
            }
        }
    }
}

.custom-rangepicker-dropdown {
    border-radius: 8px !important;
    border: 1px solid #0EC7D9 !important;
    .ant-picker-panel-container {
        .ant-picker-content {
            .ant-picker-cell-selected .ant-picker-cell-inner,
            .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
            .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
            .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
            .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
                background: #0EC7D9;
            }
            .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
            .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
            .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
                background: rgba(14, 199, 217, 0.1);
            }
            .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                border: 1px solid #0EC7D9;
            }
        }
    }
    .ant-picker-footer-extra {
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px;
    }
    .extraFooter-cancel--btn, .extraFooter-apply--btn {
        width: 93px;
        height: 40px;
        border: none;
        border-radius: 8px;
        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
        }
    }
    .extraFooter-cancel--btn {
        background: #1C202C;
        &:hover {
            background: #1C201B;
        }
    }
    .extraFooter-apply--btn {
        background: #0EC7D9;
        &:hover {
            background: #0EC7A9;
        }
        &:disabled {
            opacity: 0.2;
            background: #0EC7D9;
        }
    }
    .extraFooter-apply--btn[disabled]:hover {
        background: #0EC7A9;
    }
}

// media
@media screen and (max-width: 820px) {
    .restore-file--filter {
        .search-input, .hidden-select, .datepicker-input {
            width: 250px !important;
        }
    }
    .custom-rangepicker-dropdown {
        min-width: 250px !important;
    }
    .hidden-select-dropdown.custom-select-dropdown {
        min-width: 250px !important;
        width: 250px !important;
    }
}

@media screen and (max-width: 480px) {
    .restore-file--container .selection-actions--wrapper {
        & > div + .selection-restoreFile--btn:last-child {
            flex: 1;
        }
    }
    .restore-file--filter {
        flex-wrap: wrap;
        gap: 10px;
        .search-input {
            max-width: 300px !important;
            width: 280px !important;
        }
        &>div {
            button {
                background-color: transparent;
                height: 48px;
                width: 48px;
                border-radius: 8px;
                border-color: #00abcc;
            }
            &>div {
                width: 50px;
            }
            .hidden-select {
                width: 50px !important;
            }
        }
    }
    .restore-file--table {
        width: 320px;
        //overflow-x: scroll;
        overflow: scroll;
    }
    .custom-rangepicker-dropdown {
        .ant-picker-panels {
            flex-direction: column;
        }
    }
}
