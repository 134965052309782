@import "../../assets/_variables";

.mobile-view--wrapper {
    padding: 20px;
    img {
        z-index: 10;
    }
    .menu-wrap {
        position: absolute;
        top: 20px;
        right: 20px;
        .burger {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 8;

            /* The Burger Lines*/
            > div {
                transition: all 0.3s ease;

                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: var(--navbar-menu);

                &::before,
                &::after {
                    background: inherit;
                    position: absolute;
                    display: block;
                    content: "";
                    width: 100%;
                    height: 2px;
                }
                &::before {
                    top: -5px;
                }
                &::after {
                    top: 5px;
                }
            }
        }
        .menu {
            font-size: 2rem;

            background: var(--navbar-menu-bg);

            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;

            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            visibility: hidden;
            padding: 25% 20px;

            z-index: 2;

            > ul {
                opacity: 0;
                transition: opacity 0.2s ease 0.1s;
                li:nth-child(5) {
                    min-height: 60px;
                    .account-wrapper {
                        display: flex;
                        flex-flow: column;
                        height: 60px;
                        h5 {
                            font-family: 'Gilroy';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 28px;
                            color: #6A7186;
                        }
                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #B3B8C7;
                        }
                    }
                }
            }
            > h5 {
                font-family: 'Gilroy Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                color: #6A7186;
            }
            .menu-footer {
                position: absolute;
                bottom: 40px;
                width: 90%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .logout-div {
                height: 40px;
                background: #0EC7D9;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .ant-menu-inline.ant-menu-light {
                background: transparent;
                border-right: 0;
                .ant-menu-item.ant-menu-item-only-child {
                    padding-left: 0 !important;
                    margin: 20px 0;
                    max-height: 40px;
                    span {
                        font-family: 'Gilroy Bold';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        color: var(--navbar-text);
                    }
                    //&:after {
                    //    left: 0;
                    //    bottom: 0;
                    //    border-bottom: 3px solid #1890ff;
                    //    border-right: 0;
                    //    width: 25%;
                    //}
                }
                .ant-menu-title-content {
                    .language-selector {
                        .ant-select-selector {
                            padding-left: 0 !important;
                        }
                    }
                }
                .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active,
                .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
                    background: transparent;
                    border-right: 0;
                    padding-right: 0;
                }
            }
        }
        .toggler {
            position: relative;
            height: 20px;
            width: 20px;
            opacity: 0;
            cursor: pointer;
            z-index: 10;

            &:checked ~ .menu {
                visibility: visible;
                > ul {
                    opacity: 1;
                }
            }

            &:checked ~ .burger > div {
                transform: rotate(45deg);

                &::before,
                &::after {
                    top: 0;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.cyrrilic-fonts {
    .menu-topbar--container {
        .desktop-menu {
            .ant-menu-item {
                .ant-select-selection-item, .ant-menu-title-content {
                    font-size: 12px;
                }
            }
            .custom-select .ant-select-arrow {
                top: 24px;
                span {
                    font-size: 11px !important;
                }
            }
        }
    }
}

.menu-topbar--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    .app-logo {
        padding: 0 20px;
        cursor: pointer;
    }
    .ant-menu.desktop-menu {
        background: inherit;
        border-bottom: none;
        min-width: 600px;
        li {
            &:nth-child(5) {
                display: flex;
                align-items: center;
                padding-right: 0;
                .account-icon-wrapper {
                    img {
                        transform: translate(8px, 8px);
                    }
                }
            }
            &::after {
                border-bottom: none !important;
            }
            &:hover {
                color: #f6f7f9;
            }
            span {
                font-family: 'Gilroy Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: var(--navbar-text);
            }
        }
    }
    .account-dropdown {
        .account-icon-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #0EC7D9;
        }
    }
    .ant-menu.ant-menu-horizontal {
        .ant-menu-item-selected, .ant-menu-item-active {
            &::after {
                border-bottom: none !important;
            }
        }
        .ant-menu-item-active span {
            text-decoration: underline;
            color: var(--navbar-text-accent);
        }
    }
}

.ant-menu-submenu-popup {
    & > .ant-menu {
        background-color: #1C202C !important;
        color: #f6f7f9;
        .ant-menu-item {
            width: 80px;
            height: 44px;
            .ant-select-selector {
                padding-left: 0 !important;
            }
        }
    }
    & > .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #1C202C;
    }
}
.account-overlay-dropdown.dark .ant-dropdown-menu {
    background: #1C202C;
    li.ant-dropdown-menu-item {
        .account-block-wrapper {
            b {
                color: #FFFFFF;
            }
        }
        span {
            color: #FFFFFF;
        }
        .language-selector {
            border: 1px solid #f6f7f9;
            color: #f6f7f9;
        }
    }
}
.account-overlay-dropdown.light .ant-dropdown-menu {
    background: #f6f7f9;
    li.ant-dropdown-menu-item {
        .account-block-wrapper {
            b {
                color: #1C202C;
            }
        }
        span {
            color: #1C202C;
        }
        .language-selector {
            //border: 1px solid #1C202C;
            color: #1C202C;
        }
    }
}
.account-overlay-dropdown {
    .ant-dropdown-menu {
        box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
        backdrop-filter: blur(4px);
        border-radius: 8px;
        width: 220px;
        .ant-dropdown-menu-item-active {
            background-color: transparent;
        }
        li.ant-dropdown-menu-item {
            margin: 10px 0;
            &:hover {
                background-color: transparent;
                span {
                    color: #0EC7D9;
                }
            }
            .account-block-wrapper {
                display: flex;
                flex-direction: column;
                b {
                    font-family: 'Gilroy';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                }
                span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #B3B8C7;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 200px;
                    display: block;
                }
            }
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                display: flex;
                align-items: center;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .menu-topbar--container {
        .app-logo {
            width: 200px;
        }
    }
    .custom-select-dropdown.language-selector-dropdown {
        min-width: 60px !important;
        width: 60px !important;
    }
    .ant-menu.desktop-menu {
        min-width: 600px !important;
        .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
            padding: 0 10px;
        }
    }
    .contact-page {
        height: auto;
        margin: 0 30px;
        .info-item {
            width: 33%;
        }
    }
}
