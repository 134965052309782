// ============= THEME SETUP ============= //
:root {
  --background: #FAFAFA;
  --text-primary: #1C202C;
  --text-secondary: #7D8499;
  --text-accent: #FFFFFF;
  --modal-background: #FFFFFF;
  --modal-h2: #1C202C;
  --modal-h5: #1C202C;
  --modal-input: #FFFFFF;
  --modal-input-shadow: none;
  --modal-input-text: #32384A;
  --modal-input-border: #E8E9EE;
  --navbar-text: #7D8499;
  --navbar-text-accent: #32384A;
  --navbar-menu: black;
  --navbar-menu-bg: #f6f7f9;
  --service-card-bg: #FFFFFF;
  --service-card-bg-accent: #f6f7f9;
  --service-card-action-bg: #7D8499;
  --service-item-border-dashed: 1px dashed rgba(14, 199, 217, 0.4);
  --service-item-border-dashed-accent: rgba(14, 199, 217, 0.4);
  --modal-button-background: #F6F7F9;
  --modal-subscribe-background: #FFFFFF;
  --table-row-bg: #F6F7F9;
  --select-dropdown-bg: #FFFFFF;
  --rangepicker-dropdown-bg: #FFFFFF;
  --rangepicker-dropdown-selected: #0EC7D9;
  --footer-text: #32384A;
  --remove-modal-bg: #FFFFFF;
  --table-row-selected-bg: rgba(14, 199, 217, 0.4);
}
[data-theme='dark'] {
  --background: #121212;
  --text-primary: #FFFFFF;
  --text-secondary: #B3B8C7;
  --text-accent: #0EC7D9;
  --modal-background: #17171C;
  --modal-h2: #f6f7f9;
  --modal-h5: #FFFFFF;
  --modal-input: #17171C;
  --modal-input-shadow: rgb(16 24 40 / 5%);
  --modal-input-text: #f6f7f9;
  --modal-input-border: #565D73;
  --navbar-text: #FFFFFF;
  --navbar-text-accent: #f6f7f9;
  --navbar-menu: white;
  --navbar-menu-bg: #1C202C;
  --service-card-bg: rgba(41, 42, 47, 0.16);
  --service-card-bg-accent: rgba(41, 42, 47, 0.8);
  --service-card-action-bg: #FFFFFF;
  --service-item-border-dashed: 1px dashed rgba(14, 199, 217, 0.1);
  --service-item-border-dashed-accent: rgba(14, 199, 217, 0.1);
  --modal-subscribe-background: #F6F7F9;
  --table-row-bg: rgba(41, 42, 47, 0.16);
  --select-dropdown-bg: #161D1E;
  --rangepicker-dropdown-bg: #141318;
  --rangepicker-dropdown-selected: #0EC7D9;
  --footer-text: #EAECF0;
  --remove-modal-bg: #1C202C;
  --table-row-selected-bg: rgba(14, 199, 217, 0.1);
}

// ============= FONT SETUP ============= //
@font-face {
  font-family: "Roboto Regular";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src: url("assets/fonts/Roboto/Roboto-Light.ttf");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}


@font-face {
  font-family: "Open Sans";
  src: url('assets/fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url('assets/fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans Condensed";
  src: url('assets/fonts/OpenSansCondensed/OpenSans_Condensed-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans Condensed";
  src: url('assets/fonts/OpenSansCondensed/OpenSans_Condensed-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url('assets/fonts/Gilroy/Gilroy-Regular.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy SemiBold";
  src: url('assets/fonts/Gilroy/Gilroy-Semibold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Bold";
  src: url('assets/fonts/Gilroy/Gilroy-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

