.downloads-page--container {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    color: #f6f7f9;
    .restore-file-actions-wrapper img {
        width: 24px !important;
    }
}

@media screen and (max-width: 480px) {
    .services-page--header.create-service {
        .ant-btn.ant-btn-link {
            padding: 0;
        }
    }
    .downloads-page--container {
        h5 {
            font-size: 20px;
        }
        .custom-select {
            width: 100%;
        }
        .restore-file-actions-wrapper img {
            width: 24px !important;
        }
    }
}
