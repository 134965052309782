@import "../../assets/_variables.scss";

.mb-20 {
    margin-bottom: 20px !important;
}

.ant-layout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .services-page--header {
        width: 100%;
        max-width: 1200px;
        margin: 80px 0;
        display: flex;
        justify-content: space-between;
        .ant-btn.ant-btn-link.header-auditOfActions--btn {
            height: 40px;
            &:hover {
                background: rgba(14, 199, 217, 0.1);
                border-radius: 8px;
            }
        }
        h2 {
            font-family: 'Gilroy Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 44px;
            color: var(--text-primary);
        }
        div {
            display: flex;
            gap: 10px;
            button:first-child {
                span {
                    color: #0EC7D9;
                }
            }
        }
    }
    .services-page--wrapper {
        margin-bottom: 30px;
        width: 100%;
        max-width: 1200px;
        .empty-subscription-text {
            max-width: 570px;
            margin: 20px 0;
            h5{
                font-family: 'Gilroy SemiBold';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: var(--text-primary);
            }
            p{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #B3B8C7;
            }
        }
    }
    .create-backup--wrapper {
        width: 100%;
        max-width: 980px;
    }
    .create-btn--plus {
        width: 270px;
        height: 40px;
        background: #00abcc;
        border: none;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        &:hover, &:active, &:focus {
            background: #2A6FF7;
            color: #FFFFFF;
        }
    }
    .check-loader--container {
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 450px;
        border: 1px solid var(--background);
        box-sizing: border-box;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 30px;
        background: var(--remove-modal-bg);
        backdrop-filter: blur(8px);
        border-radius: 24px;
        max-width: 350px;
        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            color: var(--modal-input-text);
        }
        button {
            background: #F6F7F9;
            border-radius: 8px;
            border: none;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            min-width: 190px;

            height: auto;
            span {
                padding: 5px 16px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #6A7186;
            }
            &:hover, &:active, &:focus {
                background: #00abcc;
                span {
                    color: #FFFFFF;
                }
            }
        }
    }
    .sync-date-wrapper {
        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #32384A;
        }
    }
    .status-component {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        letter-spacing: -0.01em;
        color: #73BF16;
    }
    .status-component.fail {
        color: #FF403D;
    }
    .restore-btn--arrow {
        border: 1px solid #2A60F7;
        box-sizing: border-box;
        padding: 10px 16px;
        border-radius: 8px;
        width: auto;
        height: auto;
        display: flex;
        flex: 1;
        justify-content: space-around;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2A60F7;
        &:hover {
            border: 1px solid #2A60F7;
            color: #2A60F7;
        }
    }
}

.remove-modal {
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 40px 32px 32px;
        isolation: isolate;
        width: 352px;
        height: 228px;
        background: var(--remove-modal-bg);
        backdrop-filter: blur(8px);
        border-radius: 24px;
        .ant-modal-body {
            padding: 0 !important;
        }
        .ant-modal-footer {
            display: flex;
            padding: 0;
            button {
                width: 140px;
                height: 40px;
                border-radius: 8px;
                span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    letter-spacing: -0.01em;
                }
            }
            .modal-cancel--btn {
                background: #F6F7F9 !important;
                span {
                    color: #6A7186 !important;
                }
            }
            .modal-save--btn {
                background: #0EC7D9 !important;
                span {
                    color: #FFFFFF !important;
                }
            }
        }
    }
    .remove-modal--wrapper {
        h4 {
            font-family: 'Gilroy Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: var(--modal-h2);
        }
        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--text-secondary);
        }
    }
    .modal-save--btn {
        background: #c62828 !important;
        border: none;
    }
    .modal-cancel--btn {
        background: #00abcc !important;
        &:hover, &:active, &:focus {
            background: #673ab7;
        }
        &:disabled {
            background: #00abcc;
            border: none;
        }
    }
}
.ant-tooltip {
    color: #f6f7f9 !important;
    .ant-tooltip-inner {
        font-family: "Open Sans";
        font-size: 12px;
    }
}

// media
@media screen and (max-width: 480px) {
    .ant-layout-content {
        .services-page--header {
            height: auto;
            //justify-content: center;
            align-items: center;
            margin: 40px 0 20px 0;
            flex-wrap: wrap;
            gap: 20px;
            h2{
                margin-bottom: 0;
                font-size: 28px;
            }
            .ant-btn.ant-btn-link.header-auditOfActions--btn {
                background: rgba(14, 199, 217, 0.1);
                border-radius: 8px;
                width: 132px;
                height: 40px;
                span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #0EC7D9;
                }
            }
            .create-btn--plus {
                width: 100%;
            }
        }
        .check-loader--container {
            top: auto;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            .close-wrapper {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
    }
    .mobile-view--wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .remove-modal {
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: 768px) {
    .ant-layout-content .create-btn--plus {
        width: 220px;
    }
}
