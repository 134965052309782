@import "~antd/dist/antd.css";
@import "./assets/_variables";

body, #root, .ant-layout {
    //background-color: #121212;
    color: var(--modal-input-text);
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, Helvetica, sans-serif;
}

#root > .ant-layout {
    background-image: url("/assets/images/svg/bg.svg");
    background-color: var(--background);
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
}

.site-layout {
    background-color: transparent;
}

.contact-page--body {
    p {
        max-width: 640px;
    }
    a {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-decoration-line: underline;
        color: var(--text-primary);
        padding: 0 5px;
    }
}

.input-tooltip-style {
    max-width: 350px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    &>span{
        width: 20px;
        height: 20px;
        display: flex;
    }
    .ant-tooltip-content {
        .ant-tooltip-inner {
            background: #161D1E;
            border-radius: 8px;
        }
    }
}

.ant-form-item-tooltip {
    align-self: center;
    display: flex;
}

.link-notification--btn {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    span {
        text-decoration: underline;
    }
}

.resub-loader-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.site-header {
    background-color: #121212;
    .dropdown-btn {
        background-color: inherit;
        border: none;
    }
}

.display-inline {
    display: flex;
    justify-content: space-between;
}

.site-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.02);
    color: var(--footer-text);
    padding: 16px 10%;
    gap: 10px;
    div {
        display: flex;
        gap: 20px;
        a {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-decoration-line: underline;
            color: var(--footer-text);
        }
    }
    p {
        margin: 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #B3B8C7;
    }
}

.pad {
    margin: 10px 0;
}

.mt-20 {
    margin-top: 50px !important;
}

.max-width {
    max-width: 640px;
}

.displayFlex {
    display: flex;
}

.ant-pagination.custom-pagination {
    padding-top: 10px;
    background-color: transparent;
    li {
        background-color: transparent;
        border: none;
        a {
            color: var(--modal-h5);
        }
        button {
            background-color: transparent;
            border: none;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.02);
        }
    }
    .ant-pagination-prev, .ant-pagination-next {
        button span {
            color: var(--modal-h5);
        }
        button {
            padding: 0 5px;
        }
    }
    .ant-pagination-prev.ant-pagination-disabled, .ant-pagination-next.ant-pagination-disabled {
        button span {
            color: var(--text-secondary);
        }
    }
    .ant-pagination-item.ant-pagination-item-active {
        font-weight: 900;
    }
    .prev-btn--container {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    #root > .ant-layout {
        background-size: 120% 70%;
    }
    .ant-layout.site-layout {
        margin: 0;
    }
    .site-footer {
        flex-wrap: wrap;
        div {
            flex-wrap: wrap;
        }
    }
    .ant-modal.custom-modal.remove-modal {
        position: absolute;
        bottom: 0;
        margin: 0;
        max-width: 100%;
        .ant-modal-content {
            position: absolute;
            width: 100%;
            height: auto;
            bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            .ant-modal-footer {
                width: 100%;
                flex-direction: column;
                gap: 10px;
                button {
                    width: 100%;
                    &:nth-child(2) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 480px) {
    .ant-layout.site-layout {
        margin: 0 60px;
    }
}
