.support-page--wrapper {
    .download-user-guide--btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 191px;
        height: 32px;
        background: rgba(14, 199, 217, 0.1);
        border: 1px solid #0EC7D9;
        border-radius: 10000px;
        a {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #0EC7D9;
        }
    }
    p.definition {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #B3B8C7;
        margin: 0 0 30px 0;
    }
    .support-page--form {
        width: 100%;
        max-width: 450px;
        .custom-input, .ant-form-item-control-input {
            //height: 56px;
            .react-multi-email {
                background-color: transparent;
                color: #f6f7f9;
                caret-color: #f6f7f9;
                border: 1px solid #565D73;
                border-radius: 8px;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                padding: 11px;
                span {
                    line-height: 2;
                }
            }
        }
        .custom-select {
            width: 448px;
            height: 56px;
            .ant-select-selector {
                height: 56px !important;
                display: flex;
                align-items: center;
                background-color: transparent !important;
                border-radius: 8px !important;
                border: 1px solid #565D73 !important;
            }
            .ant-select-selection-item, .ant-select-arrow {
                //padding-left: 15px;
                color: #f6f7f9 !important;
            }
        }
        .custom-input.custom-input--text {
            input {
                background-color: transparent;
                color: #f6f7f9;
                caret-color: #f6f7f9;
                border: none;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                padding: 16px;

                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                &::placeholder {
                    color: #7D8499 !important;
                }
                &:hover {
                    //box-shadow: 0 0 0 30px #17171C inset !important;
                    //-webkit-box-shadow: 0 0 0 30px #17171C inset !important;
                    background: transparent !important;
                    color: #f6f7f9 !important;
                    -webkit-text-fill-color: #f6f7f9;
                }
                &:focus {
                    //box-shadow: 0 0 0 30px #17171C inset !important;
                    //-webkit-box-shadow: 0 0 0 30px transparent inset !important;
                    background: transparent !important;
                    color: #f6f7f9 !important;
                    -webkit-text-fill-color: #f6f7f9;
                }
            }
        }
        textarea {
            background: transparent;
            border-radius: 8px !important;
            border: 1px solid #565D73 !important;
            color: #f6f7f9;
            caret-color: #f6f7f9;
        }
        button[type="submit"] {
            max-width: 200px;
            background: #00abcc;
            border: 1px solid #00abcc;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            height: auto;
            padding: 0;
            margin-bottom: 0 !important;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
                padding: 10px 5px;
            }
            .ant-btn-loading-icon {
                padding: 0;
            }
        }
    }
    .ant-form-item-label label {
        color:#EAECF0;
    }
    .multi-emails-input {

    }
    .submit-btn button {
        width: 100%;
        height: 40px;
        background: #00abcc;
    }
    .ant-select-selector {
        background-color: #F6F7F9 !important;
        border: 1px solid #EAECF0 !important;
        border-radius: 0 !important;
    }
    .ant-select-arrow {
        color: #24292e !important;
    }
    .ant-select-selection-item {
        color: #24292e;
    }
    .ant-form-item .ant-input-textarea-show-count::after {
        color: #F6F7F9 !important;
    }
}

@media screen and (max-width: 820px) {
    .support-dropdown.custom-select-dropdown {
        min-width: 448px !important;
    }
}

@media screen and (max-width: 480px) {
    .support-page--wrapper .support-page--form .custom-select {
        width: 100%;
    }
}
