.desktop-view--wrapper {
    display: flex;
    width: 100%;
}

.auth-modal--wrapper {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.login_container, .login_container--mobile {
    height: 100vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
    //background-color: #121212;
    .login_form--container {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 30px;
        min-width: 350px;
        max-width: 350px;
        background: var(--modal-background);
        backdrop-filter: blur(8px);
        border-radius: 24px;
        h2 {
            color: var(--modal-h2);
        }
        h5{
            font-family: 'Gilroy Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--modal-h5);
        }
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #B3B8C7;
        }
        .go-back--btn {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--text-primary);

            padding: 0;
            gap: 10px;
            margin-bottom: 20px;
        }
    }
    .login-form {
        margin-top: 20px;
        width: 100%;
        .ant-col.ant-form-item-label {
            padding-bottom: 0;
            label {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: var(--text-primary);
            }
        }
        .ant-input-affix-wrapper {
            border: 1px solid var(--modal-input-border) !important;
            border-radius: 8px !important;
            background-color: var(--modal-input) !important;
            height: 56px !important;
            &:hover, &:focus {
                border: 1px solid #0EC7D9 !important;
            }
        }
        .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
            background-color: var(--modal-input) !important;
            border-color: #ff4d4f !important;
        }
        .custom-input.custom-input--text {
            input {
                background-color: var(--modal-input);
                color: var(--modal-input-text);
                caret-color: var(--modal-input-text);
                border: none;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px var(--modal-input-shadow);
                padding: 16px;

                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                &::placeholder {
                    color: #7D8499 !important;
                }
                &:hover {
                    box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    background: var(--modal-input) !important;
                    color: var(--modal-input-text) !important;
                    -webkit-text-fill-color: var(--modal-input-text);
                }
                &:focus {
                    box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    background: var(--modal-input) !important;
                    color: var(--modal-input-text) !important;
                    -webkit-text-fill-color: var(--modal-input-text);
                }
            }
        }

        .custom-input.custom-input--password {
            .ant-col.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper.ant-input-password {
                height: 42px;
                display: flex;
                align-items: center;
                background-color: transparent;
                border: none;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px var(--modal-input-shadow);
                input {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    background-color: var(--modal-input);
                    box-sizing: border-box;
                    box-shadow: 0px 1px 2px var(--modal-input-shadow);
                    color: var(--modal-input-text);
                    caret-color: var(--modal-input-text);
                    padding: 16px;
                    &::placeholder {
                        color: #7D8499 !important;
                        -webkit-text-fill-color: #7D8499;
                    }
                    &:focus {
                        box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                        -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                        background: var(--modal-input) !important;
                        color: var(--modal-input-text) !important;
                        -webkit-text-fill-color: var(--modal-input-text);
                        border:none;
                    }
                }
                svg {
                    fill: #f6f7f9;
                }
            }
        }

        button[type="button"] {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #0EC7D9;
            text-decoration: none;
        }

        button[type="submit"] {
            width: 100%;
            background: #00abcc;
            border: 1px solid #00abcc;
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            height: auto;
            padding: 0;
            margin-bottom: 0 !important;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
                padding: 10px 5px;
            }
            .ant-btn-loading-icon {
                padding: 0;
            }
        }
    }

    .form-actions {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
            label {
                color: var(--modal-input-text);
            }
        }
        .ant-checkbox .ant-checkbox-inner {
            background-color: var(--modal-input);
            border-color: #565D73;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #0EC7D9;
            border-color: #0EC7D9;
        }
    }

    .switch-signup {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--modal-input-text);
        margin-top: 25px;
        text-align: center;
        button {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #00abcc;
            text-decoration: none;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
        -webkit-text-fill-color: var(--modal-input-text);
        border:none !important;
    }
}

@media screen and (max-width: 480px) {
    .login_container--mobile {
        width: 100%;
        .login_form--container {
            min-width: 343px;
        }
    }
}
