.custom-modal {
    .ant-modal-content {
        .ant-modal-body {
            .signin_via_hubspot--btn {
                width: 100%;
                height: 40px;
                background: #00abcc;
                border: none;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 0;
                span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                    text-transform: uppercase;
                }
                &:hover {
                    background: #673ab7;
                }
                &:disabled {
                    background: #00abcc;
                    span {
                        color: rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
}
