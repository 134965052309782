.language-selector--wrapper {
    display: flex;
    border-radius: 4px;
    border: 1px solid #0EC7D9;
    height: 35px;
}
.language-selector {
    .ant-select-selector {
        border: none !important;
        background-color: transparent !important;
    }
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

div.language-selector {
    width: 60px;
    height: 35px;
    color: var(--modal-h2);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #0EC7D9;
        border-color: #0EC7D9;
    }
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

div.language-selector.active {
    color: #0EC7D9 !important;
    border-color: #0EC7D9 !important;
    font-weight: 800 !important;
    background: rgba(14, 199, 217, 0.1) !important;
}
