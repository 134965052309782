.subscribed-message {
    display: flex;
    flex-direction: column;
    align-items: start;
    h5 {
        font-family: 'Gilroy Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--text-primary);
    }
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #B3B8C7;
        text-align: left;
    }
}
