.theme-switch--wrapper {
    width: 100px;
    .ant-switch-handle::before {
        background-size: contain;
        transform: translateY(3px);
    }
    .ant-switch {
        height: 27px;
        background-color: #282D3E;
    }
    .ant-switch-checked {
        background-color: #E2E8F0;
    }
}

.theme-switch--wrapper.light {
    .ant-switch-handle::before {
        background-image: url("../../assets/images/svg/light.svg") !important;
        background-size: contain;
    }
}

.theme-switch--wrapper.dark {
    .ant-switch-handle::before {
        background-image: url("../../assets/images/svg/dark.svg") !important;
        background-size: contain;
    }
}
