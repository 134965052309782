.custom-modal {
    .ant-modal-content {
        border-radius: 0;
        background-color: #121212;
    }
    .ant-modal-header {
        border-radius: 0;
        border-bottom: none;
        background-color: #121212;
        border-bottom: 1px solid #EAECF0;
        .ant-modal-title {
            font-family: "Open Sans Condensed", "Helvetica Neue", "Helvetica";
            font-size: 44px;
            font-weight: bold;
            line-height: 44px;
            margin-bottom: 16px;
            color: #EAECF0;
        }
    }
    .ant-modal-footer {
        border-top: none;
        button {
            border: none;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 0;
            height: 40px;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
            }
        }
        .modal-cancel--btn {
            width: 48%;
            background: #c62828;
            color: #EAECF0;
        }
        .modal-save--btn {
            width: 49%;
            background: #00abcc;
            &:hover, &:active, &:focus {
                background: #673ab7;
            }
            &:disabled {
                background: #00abcc;
                border: none;
            }
        }
    }
    .ant-col.ant-form-item-label label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #EAECF0 !important;
    }
    .ant-col.ant-form-item-control {
        .custom-modal--input {
            height: 38px;
            width: 100%;
            background-color: #24292e !important;
            border: 1px solid #EAECF0 !important;
            border-radius: 0 !important;
            color: #EAECF0 !important;
            input {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #EAECF0 !important;
            }
        }
    }
}