.decryption-page {
    //background: #FCFCFD;
    .decryption-page--header {
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EAECF0;
        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #101828;
        }
    }
    .decryption-page-info {
        .info-row {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 90px;
            border-bottom: 1px solid #EAECF0;
            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #344054;
            }
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #667085;
            }
        }
        .info-row > div, p{
            display: flex;
            flex-direction: column;
            width: 300px;
            margin: 0;
            padding: 0;
        }
        .send-btn--icon {
            display: flex;
            gap: 10px;
            align-items: center;
            background: #2A60F7;
            border: 1px solid #2A60F7;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            height: auto;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
                padding: 3px;
            }
        }
        .show-password-btn {
            padding: 0;
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #2A60F7;
            }
        }
        .passphrase-text {
            display: block;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #032341;
            padding: 10px;
            background: #F6F7F9;
            border-radius: 8px;
            span {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}