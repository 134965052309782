.files-card--wrapper {
    max-height: 200px;
    background: rgba(41, 42, 47, 0.16);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 5px;
    & > div {
        display: flex;
        justify-content: space-between;
        & > div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        &:nth-child(1) button {
            padding: 0;
        }
        &:nth-child(1), &:nth-child(2) {
            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
            }
        };
        &:nth-child(3) span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #B3B8C7;
        }
    }
}

// media
@media screen and (max-width: 480px) {
    .ant-table-expanded-row.ant-table-expanded-row-level-1 {
        .ant-table-cell {
            padding: 16px 0;
        }
    }
}
