.about-us-page.services-page--header {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    display: flex;
    align-items: center;
    color: var(--text-primary);
    margin: 40px 0;
}

.services-page--wrapper.about-page--wrapper {
    img {
        margin-bottom: 20px;
    }
    h5 {
        font-family: 'Gilroy Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--text-primary);
    }
    a {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-decoration-line: underline;
        color: var(--text-primary);
    }
    ul {
        display: flex;
        padding: 0;
    }
    li {
        list-style: none;
        max-width: 256px;
        display: flex;
        align-items: center;
        gap: 15px;
        div {
            min-width: 72px;
            height: 72px;
            background: rgba(255, 64, 61, 0.1);
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin-bottom: 0;
            }
        }
        .refresh {
            background: rgba(0, 148, 255, 0.1);
        }
        .security {
            background: rgba(26, 206, 179, 0.1);
        }
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--text-primary);
    }
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B3B8C7;
    }
    .versioning-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #B3B8C7;
        }
    }
}

@media screen and (max-width: 480px) {
    .services-page--wrapper.about-page--wrapper {
        h5 {
            font-size: 20px;
        }
        ul {
            flex-direction: column;
            gap: 10px;
        }
    }
}
