.custom-select {
    width: 100%;
    .ant-select-selector {
        background-color: #24292e !important;
        border: 1px solid #EAECF0 !important;
        border-radius: 0 !important;
    }
    .ant-select-arrow {
        color: #F6F7F9 !important;
    }
    .ant-select-selection-item, .ant-select-item-option {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #f6f7f9;
        img {
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.custom-select-dropdown.dark {
    background: #161D1E !important;
    .ant-select-item.ant-select-item-option {
        color: #FFFFFF;
    }
}
.custom-select-dropdown.light {
    background: #FFFFFF !important;
    .ant-select-item.ant-select-item-option {
        color: #1C202C;
    }
}

.custom-select-dropdown {
    border: 1px solid #0EC7D9 !important;
    border-radius: 8px !important;
    .ant-select-item.ant-select-item-option {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        img {
            display: inline-block;
            margin-right: 10px;
        }
    }
    .ant-select-item-option-selected {
        background: rgba(14, 199, 217, 0.1) !important;
    }
    .ant-select-item-option-active {
        background-color: rgba(14, 199, 217, 0.5) !important;
    }
}
