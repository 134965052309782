@import "../../assets/_variables";

.ant-table-wrapper.custom-ant--table {
    .ant-table-thead {
        tr {
            .ant-table-cell {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #7D8499 !important;
                background-color: #ffffff !important;
            }
        }
    }
    .empty-text--wrapper {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #7D8499;
        button {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #2A60F7;
            padding: 0;
        }
    }
    .ant-table-placeholder:hover > td {
        background-color: inherit;
    }
}