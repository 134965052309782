.audit_of_actions-page--container {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    color: #f6f7f9;
    h5{
        font-family: 'Gilroy Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--text-primary);
    }
    .custom-select {
        width: 448px;
        height: 56px;
        margin: 20px 0;
        .ant-select-selector {
            height: 56px !important;
            display: flex;
            align-items: center;
            background-color: transparent !important;
            border-radius: 8px !important;
            //border: 1px solid #0EC7D9 !important;
        }
        .ant-select-selection-item, .ant-select-arrow {
            //padding-left: 15px;
            color: var(--modal-h2) !important;
        }
    }
    .ant-table {
        background: transparent !important;
    }
    .ant-table-content {
        //background-color: #2d2d2d;
        //overflow-x: scroll;
        table {
            border-spacing: 0 2px;
        }
        .ant-table-cell {
            border-bottom: 0;
            padding: 10px;
            img {
                cursor: pointer;
            }
        }
        .ant-table-row.ant-table-row-level-0:hover {
            td {
                background-color: rgba(14, 199, 217, 0.1);
            }
        }
        .ant-table-row-expand-icon-cell {
            text-align: left;
            padding: 0;
        }
        .ant-table-cell-row-hover {
            background-color: rgba(14, 199, 217, 0.1);
        }
        .restore-file-actions-wrapper img {
            width: 15px;
        }
        .ant-table-tbody {
            tr {
                background: var(--table-row-bg);
                backdrop-filter: blur(4px);
                border-radius: 4px;
            }
            .ant-table-cell span.file-name-wrapper {
                color: var(--modal-h2) !important;
            }
        }
    }
    .ant-table-content .ant-table-thead tr .ant-table-cell {
        background-color: transparent !important;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B3B8C7;
    }
    .ant-empty-description {
        color: var(--modal-h2);
    }
    .audit_of_actions-card--wrapper {
        max-height: 200px;
        background: rgba(41, 42, 47, 0.16);
        backdrop-filter: blur(4px);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 5px;
        & > div {
            display: flex;
            justify-content: space-between;
            &:nth-child(1) button {
                padding: 0;
            }
            &:nth-child(1), &:nth-child(2) {
                span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
                span.status-component {
                    color: #73BF16;
                }
                span.file-name-wrapper {
                    color: #B3B8C7;
                }
            }
            &:nth-child(2) {
                span {
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .services-page--header.create-service {
        .ant-btn.ant-btn-link {
            padding: 0;
        }
    }
    .audit_of_actions-page--container {
        h5 {
            font-size: 20px;
        }
        .custom-select {
            width: 100%;
        }
    }
}
