.create-service.services-page--header {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
        padding-left: 0;
        margin-bottom: 4px;
    }
    h2 {
        font-family: 'Gilroy Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        display: flex;
        align-items: center;
        color: var(--text-primary);
        margin-bottom: 0;
    }
}
.services-page--header.create-service {
    justify-content: start !important;
}

.services-page--wrapper.create-service--wrapper {
    .select-service-header {
        p{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: var(--text-secondary);
        }
        h5{
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--text-primary);
        }
    }
    .select-service-body .ant-spin-container {
        display: flex;
        gap: 20px;
        .service-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 88px;
            border: var(--service-item-border-dashed);
            border-radius: 8px;
            cursor: pointer;
        }
        .service-item-active {
            background: var(--service-item-border-dashed-accent);
            border: 1px solid #0EC7D9;
        }
    }
    .select-service-coming-soon {
        margin-top: 30px;
        h5{
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--text-primary);
        }
        .select-service-coming-soon-body {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            .service-item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                min-width: 200px;
                height: 88px;
                border: var(--service-item-border-dashed);
                border-radius: 8px;
                img {
                    &:before {
                        content: '';
                        background-color: rgba(0,0,0,0.5)
                    }
                }
            }
        }
    }
    .select-service-footer {
        margin-top: 20px;
        .create-btn {
            width: 200px;
            height: 40px;
            background: #00abcc;
            border: none;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            &:hover, &:active, &:focus {
                background: #2A6FF7;
                color: #FFFFFF;
            }
        }
    }
    .selected-service-header {
        h5{
            font-family: 'Gilroy Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--text-primary);
        }
    }
    .selected-service-body {
        .ant-input-affix-wrapper {
            border: 1px solid var(--modal-input-border) !important;
            border-radius: 8px !important;
            background-color: var(--modal-input) !important;
            height: 56px !important;
            &:hover, &:focus {
                border: 1px solid #0EC7D9 !important;
            }
        }
        .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
            background-color: var(--modal-input) !important;
            border-color: #ff4d4f !important;
        }
        .service-name--input {
            width: 448px;
            height: 56px;
            input {
                background-color: var(--modal-input);
                color: var(--modal-input-text);
                caret-color: var(--modal-input-text);
                border: none;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px var(--modal-input-shadow);
                padding: 16px;

                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                &::placeholder {
                    color: #7D8499 !important;
                }
                &:hover {
                    box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    background: var(--modal-input) !important;
                    color: var(--modal-input-text) !important;
                    -webkit-text-fill-color: var(--modal-input-text);
                }
                &:focus {
                    box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    -webkit-box-shadow: 0 0 0 30px var(--modal-input) inset !important;
                    background: var(--modal-input) !important;
                    color: var(--modal-input-text) !important;
                    -webkit-text-fill-color: var(--modal-input-text);
                }
            }
        }
        .create-btn {
            width: 200px;
            height: 40px;
            background: #00abcc;
            border: none;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            &:hover, &:active, &:focus {
                background: #2A6FF7;
                color: #FFFFFF;
            }
        }
        .custom-select {
            width: 448px;
            height: 56px;
            .ant-select-selector {
                height: 56px;
                display: flex;
                align-items: center;
                border-radius: 8px !important;
                background-color: #17171C !important;
                border: 1px solid #565D73 !important;
            }
            .ant-select-selection-item {
                padding-left: 15px;
            }
        }
    }
}

.ant-modal.custom-modal {
    .ant-modal-body {
        padding: 18px 24px;
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
}
.service-form {}
.setup-guide--wrapper {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    border-radius: 0;
    margin-bottom: 24px;
    span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #B3B8C7;
    }
    button {
        padding: 0 5px;
        span {
            color: #ffffff;
            text-decoration: underline;
        }
    }
}

// media
@media screen and (max-width: 480px) {
    .create-service.services-page--header {
        h2 {
            font-size: 23px;
        }
        .select-service-header {
            h5{
                font-size: 18px;
            }
        }
    }
    .create-service--wrapper {
        .select-service-body .ant-spin-container {
            flex-direction: column;
        }
    }
    .services-page--wrapper.create-service--wrapper {
        .selected-service-header h5 {
            font-size: 20px;
        }
        .selected-service-body {
            .custom-select, .service-name--input, .create-btn {
                width: 100%;
            }
        }
        .select-service-footer .create-btn {
            width: 100%;
        }
    }
    p.definition{
        font-size: 11px !important;
        line-height: 14px !important;
    }
    .definition-image {
        max-width: 300px !important;
    }
}
p.definition {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary);
    margin: 0 0 30px 0;
    span {
        color: #00abcc;
    }
}

.definition-image {
    max-width: 400px;
    margin: 20px 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}
